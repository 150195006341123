<template>
  <b-modal id="modal-review" body-class="p-4" centered hide-header hide-footer size="lg">
    <!-- Order that haven't been reviewed -->
    <template v-if="items.find(el => el.is_reviewed == false)">
      <div
        v-for="(item, indexItem) in items"
        :key="indexItem"
      >
        <div class="row">
          <!-- Product info & input rating product -->
          <div class="col-md-12 d-flex">
            <img
              :src="item.image_url"
              alt=""
              class="w-120px"
              @error="replaceByDefaultImage"
            />
            <div class="ml-3">
              <b class="review-product-name">{{ item.product_name }}</b>
              <div class="d-flex flex-lg-row flex-column align-items-lg-center mt-lg-2 mt-1">
                <div class="payment-detail">
                  Color: <span class="no-bold">{{ item.color }}</span>
                </div>
                <div class="payment-detail ml-lg-3">
                  Size: <span class="no-bold">{{ item.size }}</span>
                </div>
                <div class="payment-detail ml-lg-3">
                  QTY: <span class="no-bold">{{ item.quantity }}</span>
                </div>
              </div>
              <div class="mt-lg-2 mt-1">
                <fawesome-pro
                  v-for="stars in [1, 2, 3, 4, 5]"
                  :key="stars"
                  :variant="item.review[0].rating >= stars ? 'fas' : 'far'"
                  icon="star"
                  class="pr-1 cursor-pointer text-warning"
                  @click.native="item.review[0].rating = stars"
                />
              </div>
            </div>
          </div>
          <!-- Input product comment -->
          <div class="col-md-12 mt-2">
            <b-form-textarea
              id="review-comment"
              v-model="item.review[0].comment"
              placeholder="Tell others why you like this product..."
              no-resize
            ></b-form-textarea>
          </div>
          <!-- Product review image(s) -->
          <div class="col-md-12 mt-2 img-review-list-container">
            <!-- Selected review image list -->
            <div 
              class="img-review-container editable"
              :key="indexImage"
              v-for="(path_image, indexImage) in item.review[0].path_image"
            >
              <label :for="`update-review-img-${indexItem}-${indexImage}`">
                <img :id="`review-img-${indexItem}-${indexImage}`" class="cursor-pointer" :src="path_image"/>
              </label>
              <fawesome-pro variant="far" icon="times" class="review-img-delete cursor-pointer" @click.native="removeImage(indexItem, indexImage)" />
              <input
                type="file"
                :id="`update-review-img-${indexItem}-${indexImage}`"  
                class="d-none"
                @input="uploadFile($event, indexItem, indexImage, true)"
                accept="image/*"
              />
            </div>
            <!-- Input file for review image -->
            <div
              class="img-review-container"
              :class="{'d-none' : item.review[0].file_image.length >= 5}"
            >
              <label :for="`insert-review-img-${indexItem}`">
                <img
                  class="w-100 cursor-pointer"
                  src="@/assets/img/icon-upload.png"
                />  
              </label> 
              <input
                type="file"
                :id="`insert-review-img-${indexItem}`"
                class="d-none"
                @input="uploadFile($event, indexItem)"
                accept="image/*"
              />
            </div>
          </div>
          <!-- Select product review tags -->
          <div class="col-md-12">
            <div 
              class="chip mr-2 mt-2 cursor-pointer"
              :class="{'activated' : item.review[0].tag.indexOf(tag.name) != -1}"
              @click="toggleReviewTags(tag.name, indexItem)"
              v-for="(tag, indexTag) in tags" 
              :key="indexTag"
            >
              {{ tag.name }}
            </div>
          </div>
          <!-- Anonymous checkbox -->
          <div class="col-md-12 mt-2">
            <div class="styled-checkbox">
              <label class="container-checkbox m-0">
                Send as anonymous
                <input type="checkbox" v-model="item.review[0].anonymous"/>
                <span class="checkmark" style="top: -2px"></span>
              </label>
            </div>
          </div>
        </div>
        <hr v-if="indexItem < items.length - 1" />
      </div>
      <div class="float-right mt-3">
        <button
          type="button"
          class="btn btn-wz-outline mt-0"
          @click="$bvModal.hide('modal-review')"
        >
          LATER
        </button>
        <button
          type="button"
          class="btn btn-wz-proceed mt-0 ml-2"
          @click="submitData"
        >
          SEND REVIEW 
          <font-awesome v-if="isLoading" icon="spinner" class="mr-1" spin />
        </button>
      </div>
    </template>
    <!-- Order have been reviewed -->
    <template v-else>
      <div v-for="(item, indexItem) of items" :key="indexItem">
        <div class="row">
          <!-- Product info & rating -->
          <div class="col-md-12 d-flex">
            <img
              :src="item.image_url"
              class="w-120px"
              @error="replaceByDefaultImage"
            />
            <div class="ml-3">
              <b class="review-product-name">{{ item.product_name }}</b>
              <div class="d-flex flex-lg-row flex-column align-items-lg-center mt-lg-2 mt-1">
                <div class="payment-detail">
                  Color: <span class="no-bold">{{ item.color }}</span>
                </div>
                <div class="payment-detail ml-lg-3">
                  Size: <span class="no-bold">{{ item.size }}</span>
                </div>
                <div class="payment-detail ml-lg-3">
                  QTY: <span class="no-bold">{{ item.quantity }}</span>
                </div>
              </div>
              <div class="mt-lg-2 mt-1">
                <fawesome-pro
                  v-for="stars in [1, 2, 3, 4, 5]"
                  :key="stars"
                  :variant="item.review[0].rating >= stars ? 'fas' : 'far'"
                  icon="star"
                  class="pr-1 text-warning"
                />
              </div>
            </div>
          </div>
          <!-- Product Comment -->
          <div class="col-md-12 mt-2 text-break review-product-comment" v-if="item.review.find(el => el.rating > 0).comment">{{ item.review.find(el => el.rating > 0).comment }}</div>
          <!-- Product Review Image(s) -->
          <div class="col-md-12 mt-2 img-review-list-container" v-if="item.review.find(el => el.rating > 0).review_images">
            <div
              class="img-review-container"
              :key="indexImage"
              v-for="(image, indexImage) in item.review.find(el => el.rating > 0).review_images.filter(el => el)"
            >
              <a :href="image" target="_blank">
                <img
                  :src="image"
                  class="border w-100"
                  :alt="'Review Image ' + (indexImage + 1)"
                />
              </a>
            </div>
          </div>
          <!-- Product tags -->
          <div class="col-md-12" v-if="item.review.find(el => el.rating > 0).tag">
            <template v-for="(tag, indexTag) of item.review.find(el => el.rating > 0).tag.split(';')">
              <div class="chip mr-2 mt-2" :key="indexTag" v-if="tag">
                {{ tag }}
              </div>
            </template>
          </div>
          <!-- Reviewer name -->
          <div class="col-md-12 mt-2 reviewer-name">              
            Reviewed as <b>{{ item.review.find(el => el.rating > 0).reviewer_name }}</b>
          </div>
        </div>
        <hr v-if="indexItem < items.length - 1" />
      </div>
    </template>
  </b-modal>
</template>

<script>
import { toaster } from "@/_helpers";

export default {
  name: "ModalReview",
  props: ["items"],
  data() {
    return {
      tags: [],
      isLoading: false,
      isReviewed : Boolean,
    };
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
    apiKey() {
      return process.env.VUE_APP_API_KEY;
    },
  },
  mounted() {
    this.getReviewTags();
  },
  methods: {
    async getReviewTags() {
      try {
        const res = await this.$api.user.getProductReviewTags();
        if (res.status === 200) this.tags = res.data.data
      } catch (e) {
        console.error(e)
      }
    },
    uploadFile(event, indexItem, indexImage, update = false) {
      const file = event.target.files[0];
      let review = this.items[indexItem].review[0]

      if (["image/png", "image/jpeg"].includes(file["type"]) && file) {
        // Add / update file to array review["file_image"]
        if(update) this.$set(review.file_image, indexImage, file)
        else review.file_image.push(file);

        // Add / update path image for preview image to array review["path_image"]
        let reader = new FileReader();
        reader.onload = (e) => {
          if (update) this.$set(review.path_image, indexImage, e.target.result)
          else review.path_image.push(e.target.result);
        };
        reader.readAsDataURL(file);
        this.$emit("input", file);
      } else toaster.make("File must be an image(jpeg/png)", "danger")
    },
    removeImage(indexItem, indexImage) {
      let review = this.items[indexItem].review[0]
      review.path_image.splice(indexImage, 1)
      review.file_image.splice(indexImage, 1)
    },
    toggleReviewTags(tag, index) {
      const tags =
        this.items[index].review[0].tag != " "
          ? this.items[index].review[0].tag.split(";")
          : [];
      const found = tags.findIndex((element) => element == tag);
      if (found == -1) tags.push(tag);
      else tags.splice(found, 1);
      
      this.items[index].review[0].tag = tags.join(";");
    },
    async submitData() {
      try {
        this.isLoading = true;
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].review[0].rating == 0) {
            this.isLoading = false;
            return toaster.make("Rating cannot be empty", "danger")
          }
        }

        const form = new FormData();
        const data = [];

        for (let i = 0; i < this.items.length; i++) {
          data.push({
            title: "",
            anonymous: this.items[i].review[0].anonymous,
            comment: this.items[i].review[0].comment,
            image_url: "",
            tag: this.items[i].review[0].tag,
            rating: this.items[i].review[0].rating,
            sales_order_detail_id: this.items[i].id,
          });

          // For Push image to review_images
          for (let j = 0; j < 5; j++) {
            if (j < this.items[i].review[0].path_image.length) {
              const k = i * 5 + 1 + j;
              form.append("image_" + k, this.items[i].review[0].file_image[j]);
            }
          }
        }

        form.append("data", JSON.stringify(data));

        const res = await this.$api.user.saveProductReview(form)
        if (res.status === 200) {
          this.isLoading = false;
          toaster.make("Products Succesfully Reviewed", "success")
          this.$bvModal.hide("modal-review")
          this.$emit("refreshList")
        }
      } catch (e) {
        this.isLoading = false;
        toaster.make(e.response.data.message, "danger");
        console.error(e)
      }
    },
    replaceByDefaultImage(img) {
      img.target.src = require("@/assets/img/product-default.png");
    },
  },
};
</script>

<style scoped>
.container-checkbox {
  text-transform: none !important;
  font-size: 12px !important;
  font-weight: normal !important;
}
@media only screen and (max-width: 992px){
  .review-product-name, .review-product-comment, .reviewer-name {
    font-size: 14px;
  }
  #review-comment::placeholder {
    font-size: 12px;
  }
  .chip {
    font-size: 10px;
  }
  .payment-detail {
    font-size: 12px
  }
}
</style>