<template>
  <!--MODAL SELL BACK-->
  <b-modal
    modal-class="p-0 rounded"
    id="modal-track-order"
    size="lg"
    hide-header
    hide-footer
    centered
  >
    <div class="modal-body">
      <!-- <button
        type="button"
        @click="$bvModal.hide('modal-promo')"
        class="close"
        data-dismiss="modal"
      >
        <img src="@/assets/img/ic-close.svg" />
      </button> -->
      <template v-if="isLoaded">
        <div class="bold mb-2 text-center">ORDER STATUS</div>
        <div
          class="track-order-container custom-scrollbar"
          v-if="trackOrderData"
        >
          <ul class="timeline p-0">
            <li v-for="(orderStatus, i) in trackOrderData.manifest" :key="i">
              <div
                class="timeline--date"
                :style="
                  i == trackOrderData.manifest.length - 1
                    ? '--padding-bottom : 0px'
                    : ''
                "
              >
                <p class="mb-0">
                  {{ formatDate(orderStatus.manifest_date) }}
                </p>
                <p class="mb-0">
                  {{ orderStatus.manifest_time.substring(0, 5) }}
                </p>
              </div>
              <div
                class="timeline--circle"
                :style="
                  i == trackOrderData.manifest.length - 1 ? '--height : 0%' : ''
                "
              >
                <i></i>
              </div>
              <div
                class="timeline--description text-left"
                :style="
                  i == trackOrderData.manifest.length - 1
                    ? '--padding-bottom : 0px'
                    : ''
                "
              >
                <p class="mb-1">
                  {{ orderStatus.manifest_description }}
                </p>
                <p v-if="orderStatus.city_name" class="mb-0 bold">
                  [{{ orderStatus.city_name }}]
                </p>
              </div>
            </li>
          </ul>
        </div>
        <div v-else>
          <p class="text-center mt-4 mb-0">Track Order Data Is Empty</p>
        </div>
      </template>
      <template v-else-if="!isLoaded">
        <div class="text-center">
          <img src="@/assets/img/chat-loading.gif" alt="" style="width: 170px" />
          <h3>Please wait</h3>
        </div>
      </template>
    </div>
    <!-- test -->
  </b-modal>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "ModalTrackOrder",
  data() {
    return {
      trackOrderData: null,
      isLoaded: false,
      tracking: this.items,
    };
  },
  props: ["items"],
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
    apiKey() {
      return process.env.VUE_APP_API_KEY;
    },
  },
  mounted() {
    this.pretrack(this.tracking);
  },
  methods: {
    pretrack(tracking) {
      if (tracking?.sellers) {
        const seller = tracking.sellers[0];
        this.trackOrder(seller.courier_code, seller.tracking_number);
      } else if (tracking) {
        this.trackOrder(tracking.courier_code, tracking.tracking_number);
      }
    },
    trackOrder(courier, tracking_number) {
      let data = {
        waybill: tracking_number,
        courier: courier,
      };
      axios
        .post(this.apiUrl + "users/order/track_order", data, {
          headers: {
            api_key: this.apiKey,
            token: this.$store.getters.getUserToken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.trackOrderData = res.data.data;
          this.isLoaded = true;
        })
        .catch((err) => console.log("Track Order Error:", err));
    },
    formatDate(value) {
      return moment(String(value)).format("D MMMM YYYY");
    },
  },
  watch: {
    items(newVal) {
      this.tracking = newVal
      this.pretrack(newVal)
    }
  }
};
</script>

<style scoped>
.track-order-container {
  height: 70vh;
  overflow-y: scroll;
}
/* Custom Scrollbar */
.custom-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #808080;
}
</style>
